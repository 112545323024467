<template>
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.51743 0.921102C9.26321 0.675979 8.88088 0.602651 8.54873 0.73531C8.21657 0.86797 8 1.18049 8 1.52715V3.24259C3.58172 3.24259 0 6.69615 0 10.9563C0 15.2165 3.58172 18.67 8 18.67C12.4183 18.67 16 15.2165 16 10.9563C16 10.483 15.602 10.0992 15.1111 10.0992C14.6202 10.0992 14.2222 10.483 14.2222 10.9563C14.2222 14.2698 11.4364 16.9559 8 16.9559C4.56356 16.9559 1.77778 14.2698 1.77778 10.9563C1.77778 7.64285 4.56356 4.95676 8 4.95676V6.66963C8 7.01629 8.21657 7.32881 8.54873 7.46147C8.88088 7.59413 9.26321 7.5208 9.51743 7.27568L12.1841 4.70444C12.5312 4.36973 12.5312 3.82705 12.1841 3.49234L9.51743 0.921102Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'RefreshIcon',
}
</script>
