<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18"
      cy="18"
      r="18"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.2025 11.175C26.2025 11.1 26.2025 11.025 26.1103 10.95V10.875C26.1103 10.8 26.0181 10.725 25.926 10.65C25.8799 10.6125 25.8338 10.5937 25.7877 10.575C25.7416 10.5562 25.6955 10.5375 25.6494 10.5H25.5572C25.465 10.5 25.3729 10.5 25.2807 10.425V10.5C25.1885 10.5 25.0963 10.5 25.0041 10.575L6.56717 15.825C6.19843 15.9 5.92188 16.125 5.92188 16.5C5.92188 16.8 6.10624 17.1 6.47498 17.25L14.4029 20.1L17.9059 26.55C18.0903 26.85 18.3668 27 18.7356 27C19.1043 27 19.473 26.775 19.5652 26.475L26.0181 11.475C26.2025 11.4 26.2025 11.325 26.2025 11.175ZM21.7776 13.05L14.956 18.6L9.33271 16.575L21.7776 13.05ZM16.2466 19.65L18.7356 24.225L23.0682 14.1L16.2466 19.65Z"
      fill="black"
    />
    <mask
      id="mask0_713_14828"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="5"
      y="10"
      width="22"
      height="17"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.2025 11.175C26.2025 11.1 26.2025 11.025 26.1103 10.95V10.875C26.1103 10.8 26.0181 10.725 25.926 10.65C25.8799 10.6125 25.8338 10.5937 25.7877 10.575C25.7416 10.5562 25.6955 10.5375 25.6494 10.5H25.5572C25.465 10.5 25.3729 10.5 25.2807 10.425V10.5C25.1885 10.5 25.0963 10.5 25.0041 10.575L6.56717 15.825C6.19843 15.9 5.92188 16.125 5.92188 16.5C5.92188 16.8 6.10624 17.1 6.47498 17.25L14.4029 20.1L17.9059 26.55C18.0903 26.85 18.3668 27 18.7356 27C19.1043 27 19.473 26.775 19.5652 26.475L26.0181 11.475C26.2025 11.4 26.2025 11.325 26.2025 11.175ZM21.7776 13.05L14.956 18.6L9.33271 16.575L21.7776 13.05ZM16.2466 19.65L18.7356 24.225L23.0682 14.1L16.2466 19.65Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_713_14828)">
      <rect
        x="5"
        y="9"
        width="22.1243"
        height="18"
        fill="#E0E0E0"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SendIcon',
}
</script>
