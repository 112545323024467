<template>
  <div>
    <b-modal
      :id="modalId"
      centered
      modal-class="custom-modal-variant"
      header-close-variant="cader-gray"
      hide-footer
    >
      <template #modal-title>
        <div class="elements-center color-dark-blue">
          <span class="share-rounded-icon">
            <arrown-down-icon class="arrow-icon" />
          </span>
          <span> {{ modalTitle }} </span>
        </div>
      </template>
      <div class="mb-2 order-download-modal">
        <div class="">
          <div class="mb-1">
            <span class="color-neutral-black font-weight-bold pb-2">
              Choose a file format
            </span>
          </div>
          <div class="input-container w-100">
            <label
              v-for="(fileFormat, index) in options"
              :key="fileFormat.value + index"
              class="selected-color w-100"
            >
              <input
                type="radio"
                name="downloadType"
                :value="fileFormat.value"
                :checked="fileFormat.value === downloadType.value"
                @change="onSelectType"
              >
              <span class="text-nowrap mb-1">
                <component
                  :is="getComponent(fileFormat.value)"
                  :class="getComponentClass(fileFormat.value)"
                />
                <b-card-text class="ml-1">
                  {{ fileFormat.text }}
                </b-card-text>
              </span>
            </label>
          </div>
          <div class="text-center">
            <b-button
              variant="outline-secondary-info mr-1"
              @click="closeDownloadPopup"
            >
              Cancel
            </b-button>
            <b-button
              variant="secondary-info"
              :disabled="!downloadType || isLoading"
              @click="onClickDownload"
            >
              {{ isLoading ? 'Downloading...' : 'Download' }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BCardText, BButton, VBModal } from 'bootstrap-vue'
import { apiToastWarning } from '@/@core/utils/toast'
import ArrownDownIcon from '@/@core/assets/svg-components/ArrownDownIcon.vue'
import CsvIcon from '@/@core/assets/svg-components/CsvIcon.vue'
import XlsxIcon from '@/@core/assets/svg-components/XlsxIcon.vue'
import { FILE_FORMATS } from '@/constants'
import PdfIcon from '@/@core/assets/svg-components/PdfIcon.vue'
import ZipIcon from '@/@core/assets/svg-components/ZipIcon.vue'
import PptxIcon from '@/@core/assets/svg-components/PptxIcon.vue'

export default {
  name: 'DownloadModal',
  components: {
    BCardText,
    BButton,
    ArrownDownIcon,
    CsvIcon,
    XlsxIcon,
    PdfIcon,
    ZipIcon,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    modalId: {
      type: String,
      default() {
        return 'download_modal'
      },
    },
    modalTitle: {
      type: String,
      default() {
        return 'Download Products'
      },
    },
    isDownloading: {
      type: Boolean,
      default() {
        return false
      },
    },
    options: {
      type: Array,
      default() {
        return [{ text: null, value: null }]
      },
    },
  },
  data() {
    return {
      downloadType: this.options[0],
      isLoading: this.isDownloading,
    }
  },
  watch: {
    isDownloading(val) {
      this.isLoading = val
    },
  },
  emits: ['on-click-download'],
  methods: {
    // On select option
    onSelectType(event) {
      const fileType = event.target.value
      const selectedOption = this.options.find(option => fileType === option.value)
      this.downloadType = selectedOption
      if (!this.downloadType) {
        apiToastWarning(`File type "${fileType}" not available`)
      }
    },
    // On click cancel
    closeDownloadPopup() {
      this.isLoading = false
      this.downloadType = null
      this.$bvModal.hide(this.modalId)
    },
    // On click download
    onClickDownload() {
      this.isLoading = true
      this.$emit('on-click-download', this.downloadType)
    },
    getComponent(format) {
      if (format && format.toLowerCase().includes(FILE_FORMATS.PDF)) {
        return PdfIcon
      }
      switch (format) {
        case FILE_FORMATS.PDF:
          return PdfIcon
        case FILE_FORMATS.XLSX:
          return XlsxIcon
        case FILE_FORMATS.ZIP:
          return ZipIcon
        case FILE_FORMATS.PPTX:
          return PptxIcon
        default:
          return XlsxIcon
      }
    },
    getComponentClass(format) {
      if (format === FILE_FORMATS.XLSX) return 'xlsx-icon'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce.scss';
.arrow-icon {
  height: 12px;
  width: 15px;
}
.xlsx-icon {
  height: 28px;
  width: 28px;
  margin-left: 0;
}
</style>
