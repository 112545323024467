<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.5 7.875V24.5C24.5 25.4283 24.1313 26.3185 23.4749 26.9749C22.8185 27.6313 21.9283 28 21 28H19.25V26.25H21C21.4641 26.25 21.9092 26.0656 22.2374 25.7374C22.5656 25.4092 22.75 24.9641 22.75 24.5V7.875H19.25C18.5538 7.875 17.8861 7.59844 17.3938 7.10616C16.9016 6.61387 16.625 5.94619 16.625 5.25V1.75H7C6.53587 1.75 6.09075 1.93437 5.76256 2.26256C5.43437 2.59075 5.25 3.03587 5.25 3.5V19.25H3.5V3.5C3.5 2.57174 3.86875 1.6815 4.52513 1.02513C5.1815 0.368749 6.07174 0 7 0L16.625 0L24.5 7.875ZM2.8 20.7375H0V27.7358H1.38425V25.3872H2.7895C3.29175 25.3872 3.71875 25.2875 4.0705 25.0845C4.42575 24.8797 4.697 24.6032 4.88075 24.255C5.07148 23.8896 5.1683 23.4824 5.1625 23.0702C5.1625 22.6327 5.06975 22.2372 4.886 21.8855C4.70323 21.5358 4.4238 21.2461 4.081 21.0508C3.731 20.8407 3.30575 20.7375 2.8 20.7375ZM3.75375 23.0702C3.76006 23.3008 3.70894 23.5293 3.605 23.7352C3.51175 23.9145 3.3666 24.0615 3.1885 24.157C2.98478 24.2578 2.75944 24.3071 2.53225 24.3005H1.379V21.84H2.534C2.9155 21.84 3.21475 21.945 3.43 22.1567C3.64525 22.3703 3.75375 22.6747 3.75375 23.0702ZM5.8835 20.7375V27.7358H8.4385C9.14025 27.7358 9.723 27.5958 10.185 27.321C10.6525 27.0413 11.0176 26.6185 11.2262 26.1152C11.4537 25.5903 11.5693 24.9567 11.5693 24.2183C11.5693 23.4832 11.4555 22.8568 11.2262 22.337C11.0201 21.8395 10.6585 21.4221 10.1955 21.147C9.7335 20.874 9.14725 20.7375 8.43675 20.7375H5.8835ZM7.26775 21.8662H8.253C8.687 21.8662 9.0405 21.9538 9.31875 22.1322C9.60757 22.3211 9.82547 22.6006 9.93825 22.9268C10.0765 23.2785 10.1447 23.7178 10.1447 24.2445C10.1502 24.5935 10.1102 24.9418 10.0258 25.2805C9.9634 25.5476 9.84664 25.799 9.68275 26.019C9.53049 26.2152 9.32875 26.3675 9.09825 26.46C8.82816 26.5611 8.5413 26.6098 8.253 26.6035H7.26775V21.8662ZM13.818 24.9515V27.7358H12.4355V20.7375H16.8945V21.8803H13.818V23.835H16.6285V24.9515H13.818Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'PdfIcon',
}
</script>
