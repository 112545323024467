<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.842212 14.3158H12.6317C12.855 14.3158 13.0692 14.4045 13.2271 14.5624C13.3851 14.7204 13.4738 14.9346 13.4738 15.1579C13.4738 15.3812 13.3851 15.5954 13.2271 15.7534C13.0692 15.9113 12.855 16 12.6317 16H0.842212C0.618872 16 0.404679 15.9113 0.246754 15.7534C0.0888285 15.5954 0.000106812 15.3812 0.000106812 15.1579C0.000106812 14.9346 0.0888285 14.7204 0.246754 14.5624C0.404679 14.4045 0.618872 14.3158 0.842212 14.3158ZM7.57905 9.41053L10.31 6.68042L11.5007 7.87116L6.73695 12.6349L1.97316 7.87116L3.1639 6.68042L5.89484 9.41053V0H7.57905V9.41053Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownIcon',
}
</script>
