<template>
  <div>
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <div v-if="!orders.length && !isFilterApplied">
        <div class="items-empty-message-container">
          <div class="mb-1">
            <send-icon />
          </div>
          <b-card-text class="list-empty-text-color">
            There are no orders yet.
          </b-card-text>
        </div>
      </div>
      <div v-else>
        <div
          v-if="!isDashboardPage"
          class="row"
        >
          <div class="col-10">
            <div class="row">
              <div class="col-6 col-xxl-3 mb-xl-1">
                <b-button-group class="tabs-container w-100">
                  <b-button
                    class="text-capitalize"
                    :class="{'tab-is-active': currentTab === TABS.ALL}"
                    variant="custom-secondary tab-item"
                    :to="{path: 'orders'}"
                  >
                    {{ TABS.ALL }}
                  </b-button>
                  <b-button
                    class="text-capitalize"
                    :class="{'tab-is-active': currentTab === TABS.MARKETPLACE}"
                    variant="custom-secondary tab-item tab-filter-btn"
                    :to="{path: 'orders', query: {tab: TABS.MARKETPLACE}}"
                  >
                    {{ TABS.MARKETPLACE }}
                  </b-button>
                  <b-button
                    class="text-capitalize"
                    :class="{'tab-is-active': currentTab === TABS.DIRECT}"
                    variant="custom-secondary tab-item tab-filter-btn"
                    :to="{path: 'orders', query: {tab: TABS.DIRECT}}"
                  >
                    {{ TABS.DIRECT }}
                  </b-button>
                </b-button-group>
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-2 mt-xl-0 mb-xl-1">
                <b-dropdown
                  class="filter-dropdown"
                  variant="outline-none"
                  block
                  no-caret
                  menu-class="w-100 filter-dropdown-menu scroll-bar"
                  toggle-class="filter-dropdown-toggle"
                  no-flip
                >
                  <template #button-content>
                    <div
                      class="d-flex justify-content-between"
                      :class="{ 'has-value': !!queryParams.collectionName }"
                    >
                      <span> {{ textTruncate(queryParams.collectionName, true, 25, 20) || 'Collection' }} </span>
                      <feather-icon icon="ChevronDownIcon" />
                    </div>
                  </template>
                  <b-dropdown-text>
                    <b-form-input
                      placeholder="Search"
                      class="filter-dropdown-toggle"
                      @input="loadCollectionNames"
                    />
                    <div
                      v-if="!collectionNames.length"
                      class="py-2 text-center"
                    >
                      Nothing was found. Try another collection name.
                    </div>
                  </b-dropdown-text>
                  <b-dropdown-item
                    v-for="name in collectionNames"
                    :key="name"
                    :active="queryParams.collectionName === name"
                    @click="onSelectCollectionName(name)"
                  >
                    <div>
                      <span>
                        {{ name }}
                      </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-2 mt-xs-1 mt-md-1 mt-xl-0 mb-xl-1 pl-xl-0">
                <b-dropdown
                  class="filter-dropdown"
                  variant="outline-none"
                  block
                  no-caret
                  menu-class="w-100 filter-dropdown-menu scroll-bar"
                  toggle-class="filter-dropdown-toggle"
                  no-flip
                >
                  <template #button-content>
                    <div
                      class="d-flex justify-content-between"
                      :class="{ 'has-value': !!queryParams.entityName }"
                    >
                      <span> {{ textTruncate(queryParams.entityName, true, 25, 20) || (isBrand ? 'Retailer' : 'Brand')
                      }} </span>
                      <feather-icon icon="ChevronDownIcon" />
                    </div>
                  </template>
                  <b-dropdown-text>
                    <b-form-input
                      placeholder="Search"
                      class="filter-dropdown-toggle"
                      @input="loadEntityNames"
                    />
                    <div
                      v-if="!entityNames.length"
                      class="py-2 text-center"
                    >
                      Nothing was found. Try another {{ isBrand ? 'retailer' : 'brand' }} name.
                    </div>
                  </b-dropdown-text>
                  <b-dropdown-item
                    v-for="name in entityNames"
                    :key="name"
                    :active="queryParams.entityName === name"
                    @click="onSelectEntityName(name)"
                  >
                    <div>
                      <span>
                        {{ name }}
                      </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-1 pl-1 pl-xl-0 pr-xl-0 mt-xs-1 mt-md-1 mt-xl-0 mb-xl-1">
                <b-dropdown
                  class="filter-dropdown"
                  variant="outline-none"
                  block
                  no-caret
                  menu-class="w-100 filter-dropdown-menu scroll-bar"
                  toggle-class="filter-dropdown-toggle"
                  no-flip
                >
                  <template #button-content>
                    <div
                      class="d-flex justify-content-between"
                      :class="{ 'has-value': !!queryParams.status }"
                    >
                      <span> {{ textTruncate(ORDER_STATUS_LABELS[queryParams.status], true, 10, 8) || 'Status' }}
                      </span>
                      <feather-icon icon="ChevronDownIcon" />
                    </div>
                  </template>
                  <b-dropdown-item
                    v-for="status in getOrderStatus"
                    :key="status"
                    :active="queryParams.status === status"
                    @click="onSelectStatus(status)"
                  >
                    <div>
                      <span>
                        {{ ORDER_STATUS_LABELS[status] }}
                      </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-2 mt-xs-1 mt-md-1 mt-xl-0">
                <b-form-group class="position-relative m-0">
                  <flat-pickr
                    ref="range"
                    v-model="filterDate"
                    class="form-control bg-white date-rage-filter"
                    placeholder="Order date"
                    :config="{ dateFormat: KP_DATE_FORMAT.CONFIG, mode: 'range' }"
                    @on-change="onSelectOrderDate"
                  />
                </b-form-group>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  class="flat-pickr-caret-icon"
                />
              </div>
              <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-2 pl-1 pl-xl-0 pr-xl-0 mt-xs-1 mt-md-1 mt-xl-0 mb-xl-1">
                <b-form-group class="position-relative m-0">
                  <flat-pickr
                    ref="range"
                    v-model="expectedDate"
                    class="form-control bg-white date-rage-filter"
                    placeholder="Estimated Delivery Window"
                    :config="{ dateFormat: KP_DATE_FORMAT.CONFIG, mode: 'range' }"
                    @on-change="onSelectExpectedDate"
                  />
                </b-form-group>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="14"
                  class="flat-pickr-caret-icon"
                />
              </div>

              <div
                v-if="(isEntityAdmin || isSalesManager) && isBrand && isLoaded"
                class="col-xl-3 col-md-6 mt-xs-1 mt-md-1 mt-xl-0 mb-xl-1 order-list-filter-dropdown"
              >
                <reps-list-dropdown
                  :options="salesReps"
                  :show-self-option="true"
                  @on-select-options="onSelectedReps"
                />
              </div>
              <div
                v-if="isEntityAdmin && isBrand && isLoaded"
                class="col-xl-3 col-md-6 mt-xs-1 mt-md-1 mt-xl-0 mb-xl-1 pl-xl-0 order-list-filter-dropdown"
              >
                <reps-list-dropdown
                  :options="salesManagers"
                  placeholder="Sales manager"
                  :show-self-option="true"
                  @on-select-options="onSelectedReps"
                />
              </div>
            </div>
          </div>
          <div class="col-2">
            <b-button
              class="float-right d-flex align-items-center"
              variant="outline-primary"
              @click="$bvModal.show(EXPORT_ORDERS_MODAL_ID)"
            >
              <download-icon-2 />
              <span class="ml-10-px">
                Export Orders
              </span>
            </b-button>
          </div>
        </div>
        <b-card
          no-body
          class="mb-0"
        >
          <b-table
            v-if="isLoaded"
            ref="refOrderListTable"
            class="position-relative custom-table"
            :per-page="queryParams.limit"
            :items="orders"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            show-empty
            @row-clicked="openOrderDetails"
          >
            <template #empty>
              <div class="filtered-items-empty-message-container">
                <div class="empty-icon-bg mb-1 ">
                  <refresh-icon />
                </div>
                <b-card-text class="list-empty-text-color">
                  No orders for this request. <br>
                  Please, change your filter settings.
                </b-card-text>
              </div>
            </template>

            <!-- Column: SR Name -->
            <template #cell(salesRepName)="data">
              <b-media vertical-align="center">
                <span class="d-block text-nowrap">
                  {{ data.item.isCreatedBySelf ? profile.entityName : data.item.salesRepName }}
                </span>
              </b-media>
            </template>

            <!--- Column: Order ID -->
            <template #cell(orderSeqId)="data">
              <b-link
                :to="{ name: 'order/detail', params: { orderId: data.item._id } }"
                class="open-order"
              >
                <b-media vertical-align="center">
                  <span class="d-block text-nowrap">
                    {{ data.item.orderSeqId }}
                  </span>
                </b-media>
              </b-link>
            </template>
            <!-- Column: Retailer -->
            <template #cell(retailerName)="data">
              <b-media vertical-align="center">
                <span class="d-block text-nowrap">
                  {{ data.item.retailerName }} {{ data.item.retailerBrandIdentity ?
                    `(${data.item.retailerBrandIdentity})` : '' }}
                </span>
              </b-media>
            </template>
            <!-- Column: Brand -->
            <template #cell(brandId)="data">
              <b-media vertical-align="center">
                <b-link
                  :to="{
                    name: 'brand/detail',
                    params: { brandId: data.item.brandId },
                  }"
                  class="d-block text-nowrap"
                >
                  {{ data.item.brandName }}
                </b-link>
                <small class="text-muted">@{{ data.item.brandId }}</small>
              </b-media>
            </template>

            <!-- Column: Collection Name -->
            <template #cell(collectionName)="data">
              <b-media vertical-align="center">
                <span class="d-block text-nowrap">
                  {{ data.item.collectionName }}
                </span>
              </b-media>
            </template>

            <!-- Column: Total Amount -->
            <template #cell(totalPrice)="data">
              <b-media vertical-align="center">
                <span class="d-block text-nowrap text-right">
                  {{
                    formatCurrency(
                      data.item.totalPriceAfterDiscount || data.item.totalPrice,
                      currency(data.item)
                    )
                  }}
                  <span
                    v-if="isRetailer && data.item.discountInCredit"
                    v-b-tooltip.hover.bottom="
                      `${data.item.discountInCredit} Credit Points were applied to this shipment`
                    "
                    class="color-dark-blue cursor-pointer font-small-4"
                  >
                    CP
                  </span>
                </span>
              </b-media>
            </template>
            <template #cell(totalQuantity)="data">
              <b-media vertical-align="center">
                <span class="d-block text-nowrap text-left">
                  {{ data.item.totalQuantity }}
                </span>
              </b-media>
            </template>
            <template #head(totalPrice)>
              <b-media vertical-align="center">
                <span class="d-block text-right">
                  Total Amount
                </span>
              </b-media>
            </template>

            <!-- Head: Created At / Order Date -->
            <!-- Column: Created At / Order Date -->
            <template #cell(createdAt)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  getDateFromTimestamp(data.item.createdAt)
                }}</span>
              </div>
            </template>
            <template #cell(time)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  getFromattedTimeFromDate(data.item.createdAt)
                }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :class="getOrderStatusCssClass(data.item.status)"
                class="text-capitalize table-status"
              >
                {{ getStatusText(data.item.status) }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
        <div
          v-if="hasPagination && !isDashboardPage"
          class="my-2"
        >
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="queryParams.page"
                :total-rows="totalOrders"
                :per-page="queryParams.limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 page-link-class"
                prev-class="prev-item"
                next-class="next-item"
                @input="loadOrders"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
          <tool-kit
            v-if="$route.name === 'orders'"
            :toolkit-page="TOOLKIT_PAGE.ORDERS"
          />
        </div>

      </div>
    </template>
    <div>
      <download-modal
        :modal-id="EXPORT_ORDERS_MODAL_ID"
        modal-title="Export Orders"
        :options="Object.values(EXPORT_ORDERS_OPTIONS)"
        :is-downloading="isExportingOrders"
        @on-click-download="onExportOrders"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BButtonGroup,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BDropdownText,
} from 'bootstrap-vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { formatNumber, textTruncate } from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import {
  dataToCurrency,
  getDateFromTimestamp,
  filterDataByDate,
} from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants, {
 EXPORT_ORDERS_OPTIONS, FILE_FORMATS, TABS, TOOLKIT_PAGE,
} from '@/constants'

import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import DownloadIcon2 from '@/@core/assets/svg-components/DownloadIcon2.vue'
import RefreshIcon from '@/@core/assets/svg-components/RefreshIcon.vue'
import SendIcon from '@/@core/assets/svg-components/SendIcon.vue'
import store from '@/store'
import { GET_ORDER_LIST, GET_ORDER_FILTER_OPTIONS, ORDER_PRODUCTION_FILE_EXPORT } from '@/store/modules/order.module'
import { kpEndpoint } from '@/axios'
import { DOWNLOAD_FILE } from '@/store/modules/common.module'
import { GET_ORDER_TABLE_COLUMNS, salesRepField } from '@/table-columns-constants'
import DownloadModal from '@/@core/components/popup-modal/DownloadModal.vue'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { mapState } from 'vuex'
import UserRoleMixin from '../UserRoleMixin.vue'
import { getSearchByEndpoint, ORDER_SEARCH_FIELD } from './order-utils'
import RepsListDropdown from '../wallet/RepsListDropdown.vue'

const { formatCurrency, getCurrentUTCDateAndTime, getMonthAndYearFromDate } = utils

const {
 ORDERS_TEXT_BRAND,
  ORDERS_TEXT_RETAILER,
  ORDER_STATUS,
  ORDER_STATUS_LABELS,
  KP_DATE_FORMAT,
} = c

const EXPORT_ORDERS_MODAL_ID = 'export_orders_modal_id'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BButtonGroup,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    flatPickr,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    BDropdownText,
    DownloadIcon2,
    RefreshIcon,
    SendIcon,
    DownloadModal,
    RepsListDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin],
  props: {
    orderList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      filterDate: null,
      expectedDate: null,
      isLoaded: false,
      orders: [],
      totalOrders: 0,
      tableColumns: [],
      queryParams: {
        page: 1,
        limit: 10,
      },
      dataToCurrency,
      getDateFromTimestamp,
      filterDataByDate,
      TOOLKIT_PAGE,
      TABS,
      currentTab: TABS.ALL,
      entityNames: [],
      collectionNames: [],
      ORDER_STATUS_LABELS,
      textTruncate,
      getMonthAndYearFromDate,
      EXPORT_ORDERS_MODAL_ID,
      isExportingOrders: false,
      EXPORT_ORDERS_OPTIONS,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
      salesReps: state => state.auth.salesReps,
      salesManagers: state => state.auth.salesManagers,
    }),
    hasPagination() {
      return !!this.orders.length
    },
    isDashboardPage() {
      return this.$route.name === 'dashboard'
    },
    isFilterApplied() {
      return Object.keys(this.queryParams).length > 2
    },
    getOrderStatus() {
      return this.isBrand ? Object.keys(ORDER_STATUS_LABELS).filter(status => status !== ORDER_STATUS.NEW) : Object.keys(ORDER_STATUS_LABELS)
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.onLoadTab()
      },
    },
    role() {
      this.setTableColumns()
    },
  },
  created() {
    this.setTableColumns()
    if (this.orderList.length !== 0) {
      this.orders = this.orderList
      this.isLoaded = true
    } else {
      this.onLoadTab()
      this.loadEntityNames()
      this.loadCollectionNames()
    }
    if (!this.isDashboardPage) {
      analytics.track(
        this.isBrand
          ? constants.TRACKS.PAGES.BRAND_OPENS_ORDERS
          : constants.TRACKS.PAGES.RETAILER_OPENS_ORDERS,
      )
    }
  },
  methods: {
    setTableColumns() {
      const columns = GET_ORDER_TABLE_COLUMNS()
      if ((this.isSalesManager || this.isEntityAdmin) && this.isBrand) {
        columns.unshift(salesRepField)
      }
      this.tableColumns = columns
    },
    currency(data) {
      if (this.isBrand) {
        return data.currencyForBrand
      }
      return data.currency
    },
    onSelectedReps(selectedReps = []) {
      this.queryParams.salesRepIds = selectedReps.map(r => r.userId).join(',')
      this.loadOrders()
    },
    loadEntityNames(searchText = '') {
      const params = {
        search: searchText,
      }
      store.dispatch(GET_ORDER_FILTER_OPTIONS, { searchField: getSearchByEndpoint(this.isBrand), params }).then(res => {
        this.entityNames = res?.data?.data?.values || []
      })
    },
    loadCollectionNames(searchText = '') {
      const params = {
        search: searchText,
      }
      store.dispatch(GET_ORDER_FILTER_OPTIONS, { searchField: ORDER_SEARCH_FIELD.COLLECTION_NAME, params }).then(res => {
        this.collectionNames = res?.data?.data?.values || []
      })
    },
    onSelectStatus(status = '') {
      if (this.queryParams.status === status) {
        delete this.queryParams.status
      } else {
        this.queryParams.status = status
      }
      this.loadOrders()
   },
    getFromattedTimeFromDate(timestamp) {
      const utcDate = new Date(timestamp)
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: userTimeZone,
    }
     return new Intl.DateTimeFormat(undefined, options).format(utcDate)
  },
    onSelectEntityName(name = '') {
      if (this.queryParams.entityName === name) {
        this.queryParams.entityName = ''
      } else {
        this.queryParams.entityName = name
      }
      this.loadOrders()
    },
    onSelectCollectionName(name = '') {
      if (this.queryParams.collectionName === name) {
        this.queryParams.collectionName = ''
      } else {
        this.queryParams.collectionName = name
      }
      this.loadOrders()
    },
    onLoadTab() {
      const tab = this.$route.query.tab || TABS.ALL
      this.currentTab = tab
      switch (tab) {
        case TABS.ALL: {
          if (this.queryParams.hasOwnProperty('isSelfManagedOrder')) {
            delete this.queryParams.isSelfManagedOrder
          }
          break
        }
        case TABS.MARKETPLACE: {
            this.queryParams.isSelfManagedOrder = false
          break
        }
        case TABS.DIRECT: {
            this.queryParams.isSelfManagedOrder = true
          break
        }
        default: {
          break
        }
      }
      this.loadOrders()
    },
    onSelectOrderDate(dates) {
      if (dates.length) {
        if (dates.length === 2) {
          this.queryParams.startDate = dates[0]
          this.queryParams.endDate = new Date(dates[1]).setDate(
            new Date(dates[1]).getDate() + 1,
          )
          this.loadOrders()
        }
      } else {
        this.queryParams.startDate = ''
        this.queryParams.endDate = ''
        this.loadOrders()
      }
    },
    onSelectExpectedDate(dates) {
      if (dates.length) {
        if (dates.length === 2) {
          this.queryParams.minExpectedAvailabilityDate = dates[0]
          this.queryParams.maxExpectedAvailabilityDate = dates[1]
          this.loadOrders()
        }
      } else {
        this.queryParams.minExpectedAvailabilityDate = ''
        this.queryParams.maxExpectedAvailabilityDate = ''
        this.loadOrders()
      }
    },
    getStatusText(status) {
      if (this.isRetailer) {
        return ORDERS_TEXT_RETAILER[status] || status
      }
      return ORDERS_TEXT_BRAND[status] || status
    },
    loadOrders() {
      const params = {
        ...this.queryParams,
        page: this.queryParams.page - 1,
      }
      store.dispatch(GET_ORDER_LIST, params)
        .then(res => {
          this.orders = res.data.data.orders
          this.totalOrders = res.data.data.count
          this.isLoaded = true
          this.$emit('orders-loaded')
        })
    },
    getOrderStatusCssClass(status) {
      if (status === ORDER_STATUS.CANCELLED) return 'cancelled'
      if (status === ORDER_STATUS.REJECTED) return 'rejected'
      if (status === ORDER_STATUS.CONFIRMED) return 'confirmed'
      return 'pending'
    },
    async exportOrdersProductionFile(params) {
      store.dispatch(ORDER_PRODUCTION_FILE_EXPORT, params)
        .then(res => {
          apiToastSuccess(res.data.message)
          this.$bvModal.hide(EXPORT_ORDERS_MODAL_ID)
          this.isExportingOrders = false
        })
        .catch(err => {
          apiToastError(err)
          this.isExportingOrders = false
        })
    },
    async onExportOrders(option = '') {
      this.isExportingOrders = true
      const params = {
        ...this.queryParams,
      }
      delete params.page
      delete params.limit
      const formattedDate = getCurrentUTCDateAndTime()
      let fileName = `Order Export ${formattedDate}.xlsx`
      if (option.value === EXPORT_ORDERS_OPTIONS.PRODUCTION_FILE.value) {
        if (this.isBrand) {
          await this.exportOrdersProductionFile(params)
          return
        }
        params.isDetailed = true
        fileName = `Production File ${formattedDate}.xlsx`
      }
      const payload = {
        url: kpEndpoint.order.download,
        fileName,
        fileFormat: FILE_FORMATS.XLSX,
        params,
      }
      store.dispatch(DOWNLOAD_FILE, payload)
        .then(() => {
          this.$bvModal.hide(EXPORT_ORDERS_MODAL_ID)
          this.isExportingOrders = false
          analytics.track(
            this.isBrand
              ? constants.TRACKS.ACTIONS.BRAND_EXPORTS_ORDERS
              : constants.TRACKS.ACTIONS.RETAILER_EXPORTS_ORDERS,
          )
        })
    },
    openOrderDetails(data) {
      this.$router.push({
        name: 'order/detail',
        params: { orderId: data._id },
      })
    },
  },
  setup() {
    return {
      formatNumber,
      formatCurrency,
      KP_DATE_FORMAT,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/page-common.scss';
</style>

<style lang="scss">
.order-list-filter-dropdown {
  .filter-dropdown .filter-dropdown-toggle-class {
    padding: 13px !important;
    font-size: 0.95rem;
  }
}
</style>
