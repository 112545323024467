<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74999 9.75C8.74999 9.28587 8.93437 8.84075 9.26256 8.51256C9.59075 8.18437 10.0359 8 10.5 8H12.25C12.7141 8 13.1592 8.18437 13.4874 8.51256C13.8156 8.84075 14 9.28587 14 9.75V11.3915L14.7 14.1897C14.7872 14.5399 14.7641 14.9084 14.6337 15.2448C14.5034 15.5813 14.2723 15.8692 13.972 16.0693L12.3445 17.1542C12.0569 17.3461 11.719 17.4485 11.3732 17.4485C11.0275 17.4485 10.6896 17.3461 10.402 17.1542L8.77624 16.0693C8.47626 15.869 8.24548 15.5809 8.11548 15.2445C7.98548 14.9081 7.96261 14.5397 8.04999 14.1897L8.74999 11.3915V9.75ZM12.25 9.75H10.5V11.3915C10.5 11.5349 10.4823 11.6777 10.4475 11.8167L9.74749 14.6133L11.375 15.6982L13.0007 14.6133L12.3007 11.8167C12.2665 11.6776 12.2494 11.5348 12.25 11.3915V9.75Z"
      fill="currentColor"
    />
    <path
      d="M24.5 7.875V24.5C24.5 25.4283 24.1312 26.3185 23.4749 26.9749C22.8185 27.6313 21.9282 28 21 28H14H5.24999V20.125H3.49999V3.5C3.49999 2.57174 3.86874 1.6815 4.52512 1.02513C5.1815 0.368749 6.07174 0 6.99999 0H16.625L24.5 7.875ZM19.25 7.875C18.5538 7.875 17.8861 7.59844 17.3938 7.10616C16.9016 6.61387 16.625 5.94619 16.625 5.25V1.75H13.125V3.5H11.375V5.25H13.125V7H10.5L10.5 5.25H8.74999V3.5H10.5V1.75H6.99999C6.53586 1.75 6.09074 1.93437 5.76256 2.26256C5.43437 2.59075 5.24999 3.03587 5.24999 3.5V20.125V28H14V26.25H21C21.4641 26.25 21.9092 26.0656 22.2374 25.7374C22.5656 25.4092 22.75 24.9641 22.75 24.5V7.875H19.25Z"
      fill="currentColor"
    />
    <path
      d="M0.52602 28V27.2591L3.89387 22.4286H0.506775V21.4311H5.36931V22.172L2.00467 27.0025H5.38856V28H0.52602Z"
      fill="currentColor"
    />
    <path
      d="M7.54937 21.4311V28H6.3594V21.4311H7.54937Z"
      fill="currentColor"
    />
    <path
      d="M8.65733 28V21.4311H11.1207C11.6253 21.4311 12.0487 21.5252 12.3908 21.7133C12.7351 21.9015 12.9949 22.1602 13.1703 22.4896C13.3477 22.8167 13.4365 23.1888 13.4365 23.6058C13.4365 24.027 13.3477 24.4012 13.1703 24.7284C12.9928 25.0555 12.7308 25.3132 12.3844 25.5014C12.038 25.6874 11.6114 25.7804 11.1046 25.7804H9.47203V24.8021H10.9443C11.2393 24.8021 11.481 24.7508 11.6692 24.6482C11.8573 24.5455 11.9963 24.4044 12.0861 24.2248C12.1781 24.0452 12.224 23.8388 12.224 23.6058C12.224 23.3727 12.1781 23.1674 12.0861 22.9899C11.9963 22.8124 11.8563 22.6745 11.6659 22.5762C11.4778 22.4757 11.2351 22.4254 10.9378 22.4254H9.8473V28H8.65733Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ZipIcon',
}
</script>
