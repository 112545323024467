<template>
  <b-dropdown
    class="filter-dropdown"
    variant="outline-none"
    block
    no-caret
    menu-class="w-100 filter-dropdown-menu scroll-bar"
    toggle-class="filter-dropdown-toggle filter-dropdown-toggle-class"
    no-flip
  >
    <template #button-content>
      <div
        class="d-flex justify-content-between"
        :class="{ 'has-value': !!selectedNames.length }"
      >
        <span> {{ placeholder }} {{ selectedNames.length ? `(${selectedNames.length})` : '' }} </span>
        <feather-icon icon="ChevronDownIcon" />
      </div>
    </template>
    <b-dropdown-text>
      <b-form-input
        placeholder="Search"
        class="filter-dropdown-toggle"
        @input="onSearchSalesRep"
      />
      <div
        v-if="!filteredSalesReps.length"
        class="py-2 text-center"
      >
        Nothing was found. Try another name.
      </div>
      <div
        v-else
        class="sr-filter-options"
      >
        <b-form-checkbox-group
          id="sales_rep"
          v-model="selectedRepIds"
          name="sales_rep"
          stacked
          @input="onSelectOption"
        >
          <b-form-checkbox
            v-for="option in filteredSalesReps"
            :key="option.userId"
            :value="option.userId"
            class="sr-filter-checkbox"
          >
            <div class="checkbox-div">
              <span>{{ option.name }}</span>
            </div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </b-dropdown-text>
  </b-dropdown>
</template>

<script>
import {
  BDropdown, BDropdownText, BFormCheckbox, BFormCheckboxGroup, BFormInput,
} from 'bootstrap-vue'
import { textTruncate } from '@/@core/utils/utils'
import { getSelfOption } from '@/service/user.service'

export default {
  name: 'RepsListDropdown',
  components: {
    BDropdown,
    BDropdownText,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    showSelfOption: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Sales representative',
    },
  },
  data() {
    return {
      textTruncate,
      selectedRepIds: [],
      selectedNames: [],
      filteredSalesReps: [],
    }
  },
  watch: {
    showSelfOption() {
      this.setOptions()
    },
    options: {
      handler() {
        this.setOptions()
      },
      deep: true,
    },
  },
  created() {
    this.setOptions()
  },
  emits: ['on-select-options'],
  methods: {
    setOptions() {
      if (this.showSelfOption) {
          this.filteredSalesReps = [getSelfOption(), ...this.options]
        } else {
          this.filteredSalesReps = this.options
        }
    },
    onSearchSalesRep(seatchText = '') {
      if (!seatchText) {
        this.setOptions()
        return
      }
      this.filteredSalesReps = this.options.filter(s => s.name.toLowerCase().includes(seatchText.toLowerCase()))
    },
    onSelectOption(selectedIds = []) {
      this.selectedRepIds = selectedIds
      const selectedValues = this.filteredSalesReps.filter(s => selectedIds.includes(s.userId))
      this.selectedNames = selectedValues.map(s => s.name)
      this.$emit('on-select-options', selectedValues)
    },
  },
}
</script>

<style lang="scss">
.sr-filter-options {
  .sr-filter-checkbox {
    margin-top: 20px;
  }
}

.filter-dropdown .filter-dropdown-toggle-class {
  padding: 11px !important;
}
</style>
