<template>
  <svg
    id="Capa_1"
    fill="#000000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="28"
    height="28"
    viewBox="0 0 548.291 548.291"
    xml:space="preserve"
  >
    <g>
      <path
        d="M222.581,262.339c-5.504,0-9.229,0.538-11.176,1.061v35.292c2.312,0.541,5.145,0.715,9.046,0.715
		c14.374,0,23.237-7.276,23.237-19.519C243.688,268.904,236.051,262.339,222.581,262.339z"
      />
      <path
        d="M486.201,196.116h-13.166V132.59c0-0.399-0.062-0.795-0.115-1.2c-0.021-2.522-0.825-5-2.552-6.96L364.657,3.677
		c-0.033-0.034-0.064-0.044-0.085-0.075c-0.63-0.704-1.364-1.292-2.143-1.796c-0.229-0.157-0.461-0.286-0.702-0.419
		c-0.672-0.365-1.387-0.672-2.121-0.893c-0.2-0.052-0.379-0.134-0.577-0.188C358.23,0.118,357.401,0,356.562,0H96.757
		C84.894,0,75.256,9.649,75.256,21.502v174.613H62.092c-16.971,0-30.732,13.756-30.732,30.73v159.81
		c0,16.966,13.761,30.736,30.732,30.736h13.164V526.79c0,11.854,9.638,21.501,21.501,21.501h354.776
		c11.853,0,21.501-9.647,21.501-21.501V417.392h13.166c16.966,0,30.729-13.764,30.729-30.731V226.854
		C516.93,209.872,503.167,196.116,486.201,196.116z M96.757,21.502h249.054v110.006c0,5.94,4.817,10.751,10.751,10.751h94.972
		v53.861H96.757V21.502z M278.639,265.544v-22.711h91.863v22.711h-32.63v96.822h-27.128v-96.822H278.639z M270.452,279.195
		c0,11.696-3.89,21.622-10.987,28.363c-9.228,8.692-22.887,12.604-38.837,12.604c-3.556,0-6.75-0.179-9.228-0.54v42.74h-26.78
		V244.429c8.336-1.417,20.045-2.488,36.536-2.488c16.68,0,28.555,3.194,36.544,9.575
		C265.326,257.552,270.452,267.484,270.452,279.195z M81.578,362.362V244.429c8.336-1.417,20.034-2.488,36.526-2.488
		c16.675,0,28.549,3.194,36.536,9.575c7.627,6.037,12.766,15.968,12.766,27.669c0,11.696-3.903,21.627-10.998,28.368
		c-9.229,8.692-22.876,12.598-38.842,12.598c-3.536,0-6.746-0.179-9.229-0.535v42.74H81.578V362.362z M451.534,520.962H96.757
		v-103.57h354.776V520.962z M451.041,362.362l-10.824-21.638c-4.425-8.336-7.276-14.541-10.646-21.454h-0.346
		c-2.478,6.913-5.503,13.118-9.219,21.454l-9.937,21.638h-30.852l34.573-60.488l-33.329-59.041h31.028l10.458,21.819
		c3.555,7.268,6.215,13.122,9.05,19.861h0.356c2.835-7.633,5.145-12.953,8.159-19.861l10.109-21.819h30.867l-33.702,58.347
		l35.466,61.182H451.041z"
      />
      <path
        d="M140.627,279.888c0-10.989-7.628-17.554-21.109-17.554c-5.487,0-9.231,0.538-11.181,1.061v35.297
		c2.315,0.535,5.145,0.715,9.053,0.715C131.763,299.407,140.627,292.135,140.627,279.888z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PptxIcon',
}
</script>
